export function initializeReadMoreLink() {
  const containers = document.querySelectorAll('.text.text-shorten-p');

  containers.forEach((container) => {
    const elements = container.querySelectorAll('p');

    // Check if the read-more link already exists
    if (container.querySelector('.read-more')) {
      return;
    }

    // Calculate the combined height of the p elements
    let combinedHeight = 0;
    elements.forEach((element) => {
      combinedHeight += element.offsetHeight;
    });

    elements.forEach((element, index) => {
      if (index > 2) {
        element.classList.add('text-shorten');
      }
    });

    if (combinedHeight > container.clientHeight) {
      const readMoreLink = document.createElement('a');
      readMoreLink.textContent = '...mehr lesen';
      readMoreLink.href = '#';
      readMoreLink.classList.add('read-more');

      readMoreLink.addEventListener('click', (event) => {
        event.preventDefault();
        elements.forEach((element) => element.classList.remove('text-shorten'));
        readMoreLink.remove();
        container.style.overflow = 'visible';
        container.style.maxHeight = 'unset';
      });

      container.appendChild(readMoreLink);
    }
  });
}
