import { initializeReadMoreLink } from './read-more-link.js';
import $ from '../jquery-shim.js';

export function toggleCategoryTabs() {
  $(document).ready(function () {
    const $tabs = $('[data-tab-index]');
    const $tabContents = $('.tab-content');
    const $mainElement = $('main#main');
    const $productFilterElement = $('div#shisha-category-filter');
    const $categoryCards = $('div.category-cards-container');
    const $staticElements = $mainElement
      .add($productFilterElement)
      .add($categoryCards); // Group static elements

    $tabs.on('click', function () {
      const index = $(this).data('tab-index');

      // Remove active classes from all tabs
      $tabs
        .removeClass('sh_active_tab tw-text-black tw-font-bold')
        .addClass('tw-text-dh_dark tw-font-semibold');

      // Hide all tab contents with fade out effect
      $tabContents.fadeOut(300, function () {
        $(this).addClass('tw-hidden');
      });

      // Add active class to the clicked tab
      $(this)
        .addClass('sh_active_tab tw-text-black tw-font-bold')
        .removeClass('tw-text-dh_dark tw-font-semibold');

      // Handle "static" tab logic (showing and hiding the associated elements)
      if (index === 'static') {
        $staticElements.removeClass('tw-hidden'); // Show all static elements together
      } else {
        // Show the corresponding tab content with fade in effect
        $(`#tab-${index}`).removeClass('tw-hidden').fadeIn(300);

        // Hide the elements associated with the "static" tab
        $staticElements.addClass('tw-hidden').fadeOut(300); // Hide all static elements together
      }

      // Initialize read more links for the newly displayed content
      initializeReadMoreLink();
    });

    // Initialize read more links for the initially visible content
    initializeReadMoreLink();
  });
}
