import $ from '../jquery-shim.js';

export function listenToStickyFilter() {
  var $filter = $('#shisha-category-filter');
  if ($filter.length) {
    var observer = new IntersectionObserver(
      function (entries) {
        // If the element is intersecting, it's not sticky yet, so remove the class
        // If it's not intersecting, it's sticky, so add the class
        if (entries[0].isIntersecting) {
          $filter.removeClass(
            'tw-border-0 tw-border-b tw-border-solid tw-border-dh_light'
          );
        } else {
          $filter.addClass(
            'tw-border-0 tw-border-b tw-border-solid tw-border-dh_light'
          );
        }
      },
      {
        // Set the threshold to 1.0, so the callback is called when 100% of the element is visible
        threshold: 1.0,
      }
    );

    // Create a sentinel element before the filter
    var $sentinel = $('<div>').insertBefore($filter);

    // Start observing the sentinel
    observer.observe($sentinel[0]);
  }
}
