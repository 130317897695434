import $ from '../jquery-shim.js';

export function initializeImageNavigation() {
  const $flexViewport = $('.woocommerce-product-gallery .flex-viewport');
  if ($flexViewport.length) {
    $flexViewport.append(
      '<div class="slider-nav" style="position: absolute; top: 50%; transform: translateY(-50%); width: 100%; display: flex; justify-content: space-between; pointer-events: none;"><a href="#" class="nav-prev" style="pointer-events: all; margin-left: 10px;"><i class="fas fa-chevron-left" ></i></a><a href="#" class="nav-next" style="pointer-events: all; margin-right: 10px;"><i class="fas fa-chevron-right" ></i></a></div>'
    );

    $('.nav-prev').on('click', function (e) {
      e.preventDefault();
      $('.woocommerce-product-gallery').flexslider('prev');
    });

    $('.nav-next').on('click', function (e) {
      e.preventDefault();
      $('.woocommerce-product-gallery').flexslider('next');
    });
  }

  // Fixes a bug with the loading of images on the product page
  document
    .querySelectorAll('.woocommerce-product-gallery__image:not(:first-of-type)')
    .forEach(function (el) {
      el.style.visibility = 'visible';
      el.style.opacity = '1';
    });
}
