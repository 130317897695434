export function getWeglotLanguage() {
  const url = new URL(window.location.href);
  const urlParts = url.pathname.split('/');
  const lang = urlParts[1];

  // Supported languages
  const supportedLanguages = ['fr', 'en', 'it', 'de'];

  // If the query parameter or the first part of the URL is a valid language, return it; otherwise, default to 'de' (untranslated)
  return supportedLanguages.includes(lang) ? lang : 'de';
}
