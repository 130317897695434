import { getWeglotLanguage } from '../global/weglot-language.js';

const CLOUD_FLARE_WORKER_URL = shisha_values.cloudflareServiceUrl;

export function updateDeliveryInfo() {
  const stockDeliveryDiv = document.getElementById('stock-delivery-div');
  if (!stockDeliveryDiv) {
    return;
  }

  const cacheKey = 'shisha_delivery_info';
  const cachedData = localStorage.getItem(cacheKey);

  const now = new Date();
  const cacheTTL = 5 * 60 * 1000; // 5 minutes

  // Get the current language using Weglot
  const currentLanguage = getWeglotLanguage() || 'de'; // Default to 'de' if no language is set

  if (cachedData) {
    const cached = JSON.parse(cachedData);

    if (now.getTime() < cached.timestamp + cacheTTL) {
      displayDeliveryInfo(cached.data, currentLanguage);
      return;
    }
  }

  fetch(`${CLOUD_FLARE_WORKER_URL}/get-delivery-info`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      localStorage.setItem(
        cacheKey,
        JSON.stringify({ data, timestamp: now.getTime() })
      );
      displayDeliveryInfo(data, currentLanguage);
    })
    .catch((error) => console.error('Error during fetch:', error));
}

function displayDeliveryInfo(data, language) {
  // Use the requested language or fallback to 'de'
  const deliveryData = data[language] || data['de'];

  if (deliveryData && deliveryData.holidayMessage) {
    const weekendDeliveryElement = document.getElementById('weekenddelivery');
    if (weekendDeliveryElement) {
      weekendDeliveryElement.innerHTML = deliveryData.holidayMessage;
    } else {
    }
  }

  if (deliveryData && deliveryData.plannedDelivery) {
    const plannedDeliveryElement = document.getElementById('planned-delivery');
    if (plannedDeliveryElement) {
      plannedDeliveryElement.innerHTML = deliveryData.plannedDelivery;
    }
  }
}
