import $ from '../jquery-shim.js';

export function toggleAccordion() {
  function initializeAccordion($accordion) {
    // Unbind any previous click event to avoid multiple bindings
    $accordion
      .find('.sh-accordion-header')
      .off('click')
      .on('click', function () {
        const $thisHeader = $(this);
        const content = $thisHeader.next('.sh_accordion-content');
        const iconUp = $thisHeader.find('.sh-chevron-up');
        const iconDown = $thisHeader.find('.sh-chevron-down');

        // Close all other accordion contents
        $accordion
          .find('.sh_accordion-content')
          .not(content)
          .slideUp(300)
          .prev('.sh-accordion-header')
          .removeClass('tw-bg-dh_gray')
          .find('.sh-chevron-up')
          .addClass('tw-hidden')
          .siblings('.sh-chevron-down')
          .removeClass('tw-hidden');

        // Open the clicked accordion content
        content.stop(true, true).slideToggle(300);

        // Toggle icons
        iconUp.toggleClass('tw-hidden');
        iconDown.toggleClass('tw-hidden');

        // Toggle background color
        $thisHeader.toggleClass('tw-bg-dh_gray');
      });
  }

  // Initialize all accordions on the page
  $('.sh-accordion').each(function () {
    initializeAccordion($(this));
  });

  // Re-initialize when dynamically added content is shown or new accordions are added
  $(document).on('accordion-reinitialize', function () {
    $('.sh-accordion').each(function () {
      initializeAccordion($(this));
    });
  });
}
