import $ from '../jquery-shim.js';

export function initializeSearchSuggestions() {
  $(document).on('fibosearch/show-mobile-overlay', () => {
    const search_suggestions_html = shisha_values.searchfieldshtml;

    $('.dgwt-wcas-suggestions-wrapp.woocommerce.dgwt-wcas-has-img').before(
      search_suggestions_html
    );
  });
}
