import 'overlayscrollbars/overlayscrollbars.css';

import { OverlayScrollbars } from 'overlayscrollbars';

import $ from '../jquery-shim.js';

function initializeScrollbar($container, showScrollbar) {
  const scrollbarTheme = $container.data('scrollbar-theme');

  if (showScrollbar) {
    showScrollbar = 'auto';
  } else {
    showScrollbar = 'hidden';
  }

  OverlayScrollbars($container[0], {
    overflowBehavior: {
      x: 'visible',
      y: 'hidden',
    },
    scrollbars: {
      visibility: showScrollbar,
      autoHide: 'never',
      theme: scrollbarTheme, // Set the theme property to "shisha"
    },
  });
}

export function generateScrollbars() {
  function scrollbarInitializer(scrollbarContainer) {
    const showDesktop = scrollbarContainer.data('scrollbar-show-desktop');
    const showMobile = scrollbarContainer.data('scrollbar-show-mobile');

    const isDesktop = window.innerWidth >= 1024;

    // Determine if either attribute is present
    const hasAttributes =
      typeof showDesktop !== 'undefined' || typeof showMobile !== 'undefined';

    // Determine if we should initialize the scrollbar
    const showScrollbar =
      (isDesktop && showDesktop) ||
      (!isDesktop && showMobile) ||
      !hasAttributes;

    if (isDesktop && showDesktop === false) {
      return; // Do not initialize scrollbar on desktop if showDesktop is false
    }

    initializeScrollbar(scrollbarContainer, showScrollbar);
  }

  // Mutation Observer callback
  function observerCallback(mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' || mutation.type === 'attributes') {
        $(mutation.target)
          .find('[data-scrollbar-role="content"]')
          .each(function () {
            if ($(this).is(':visible') && !$(this).data('initialized')) {
              scrollbarInitializer($(this));
            }
          });
      }
    }
  }

  // Initialize scrollbars for visible containers
  $('[data-scrollbar-role="content"]').each(function () {
    if ($(this).is(':visible')) {
      scrollbarInitializer($(this));
    }
  });

  // Create an observer instance and start observing
  const observer = new MutationObserver(observerCallback);
  observer.observe(document.body, {
    childList: true,
    attributes: true,
    subtree: true,
  });
}
