export function initializeVariationDropdown() {
  const dropdowns = document.querySelectorAll('.custom-variation-dropdown');

  dropdowns.forEach((dropdown) => {
    const selectedElement = dropdown.querySelector('.custom-selected-element');
    const optionsDiv = dropdown.querySelector('.custom-options-div');

    if (selectedElement && optionsDiv) {
      selectedElement.addEventListener('click', () => {
        toggleDropdown(selectedElement, optionsDiv);
      });

      optionsDiv.addEventListener('click', (e) => {
        const option = e.target.closest('.custom-option');
        if (option) {
          const url = option.dataset.url;
          if (url && !window.location.href.includes(url)) {
            window.location.href = url;
            toggleDropdown(selectedElement, optionsDiv);
          }
        }
      });

      document.addEventListener('click', (e) => {
        if (!dropdown.contains(e.target)) {
          closeDropdown(selectedElement, optionsDiv);
        }
      });
    }
  });
}

function toggleDropdown(selectedElement, optionsDiv) {
  optionsDiv.style.display =
    optionsDiv.style.display === 'block' ? 'none' : 'block';
  selectedElement.classList.toggle('opened');
}

function closeDropdown(selectedElement, optionsDiv) {
  optionsDiv.style.display = 'none';
  selectedElement.classList.remove('opened');
}
