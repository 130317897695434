import $ from '../jquery-shim.js';

export function addWidgetClickHandler() {
  $('.trustedShopsWidget.trustedShopsProductWidget').on('click', function (e) {
    e.preventDefault();

    $('li#tab-title-ts_reviews a').click();

    $('html, body').animate(
      {
        scrollTop: $('li#tab-title-ts_reviews').offset().top,
      },
      800
    );
  });
}
