import $ from '../jquery-shim.js';

let isAnimationRunning = false;

const roundoff = (value) => {
  return (Math.round(value / 0.05) * 0.05).toFixed(2);
};

const animateProgressBar = () => {
  if (!isAnimationRunning) {
    isAnimationRunning = true;
    let width = 10;
    const $elem = $('#myBar');
    const interval = setInterval(() => {
      if (width >= 100) {
        clearInterval(interval);
        isAnimationRunning = false;
        $elem.hide();
        $('.surveyelfbarwrap').fadeIn();
      } else {
        width++;
        $elem.css('width', width + '%').text(width + '%');
      }
    }, 10);
  }
};

export function initializeElfbarCalculator() {
  $('.showmeanalert').click(function () {
    $('.surveyelfbarwrap').hide();

    const inputvalue = $('#amountofcigarettes').val();

    if (inputvalue != '' && $.isNumeric(inputvalue)) {
      $('#surveyerror').hide();
      $('#myBar').show();
      animateProgressBar();

      const dailycigs = Number(inputvalue);
      const monthlycigs = roundoff(dailycigs * 0.425 * 31);
      const yearlycigs = roundoff(dailycigs * 0.425 * 365);
      const elfbar600savings = roundoff(0.2833 * dailycigs * 365);
      const elfbar1500savings = roundoff(0.3456 * dailycigs * 365);
      const elfbar2500savings = roundoff(0.3454 * dailycigs * 365);
      const elfbar5000savings = roundoff(0.3812 * dailycigs * 365);
      const elfbarelfasavings = roundoff(0.3591 * dailycigs * 365);
      const elfbarelfliqsavings = roundoff(0.4053 * dailycigs * 365);

      $('#elfbar600savings').text('CHF ' + elfbar600savings);
      $('#elfbar1500savings').text('CHF ' + elfbar1500savings);
      $('#elfbar2500savings').text('CHF ' + elfbar2500savings);
      $('#elfbar5000savings').text('CHF ' + elfbar5000savings);
      $('#elfbarelfasavings').text('CHF ' + elfbarelfasavings);
      $('#elfbarelfliqsavings').text('CHF ' + elfbarelfliqsavings);

      $('#monatlichezigaretten').text('CHF ' + monthlycigs);
      $('#jaehrlichezigaretten').text('CHF ' + yearlycigs);
    } else {
      $('#surveyerror').show();
    }
  });
}
