import $ from '../jquery-shim.js';

export function initializeMobileMenu() {
  const menuTargetNode = document.querySelector('#main-menu');
  const bodyElementForMenu = document.querySelector('body');

  // make sure we actually have a main menu
  if (menuTargetNode) {
    const observerOptions = {
      attributes: true,
      attributeFilter: ['class'],
    };

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'attributes') {
          if ($(menuTargetNode).hasClass('mfp-hide')) {
            $(bodyElementForMenu).removeClass('mobile-menu-is-showing');
            $(menuTargetNode).hide();
          } else {
            $(bodyElementForMenu).addClass('mobile-menu-is-showing');
            $(menuTargetNode).show();
          }
        }
      });
    });

    observer.observe(menuTargetNode, observerOptions);

    $('#main-menu').on(
      'click',
      '.sh_menu-item.sh_has-children > a',
      function (event) {
        event.preventDefault();

        const $subMenu = $(this).next('.sh_sub-menu');
        // Remove 'current-slide' from any active sub-menu
        $('.sh_sub-menu.current-slide')
          .removeClass('current-slide')
          .css('overflow', 'unset');

        // Add 'current-slide' to the clicked sub-menu
        setTimeout(() => {
          $subMenu.addClass('current-slide');
          $subMenu.css('overflow', 'unset').css('overflow', 'scroll');
        }, 50);

        $('#menu-hauptmenue-mobile').addClass('submenu-active');

        // Remove 'active' from any active sub-menu that is not a parent of the clicked sub-menu
        $('.sh_sub-menu.active')
          .not($subMenu.parents('.sh_sub-menu'))
          .animate({ left: '100%' }, 200, function () {
            $(this).removeClass('active').css('overflow', 'unset');
          });

        // If the clicked sub-menu was not already active, add 'active' class
        if (!$subMenu.hasClass('active')) {
          $subMenu
            .css('left', '100%')
            .addClass('active')
            .animate({ left: '0' }, 200);
          setTimeout(() => {
            $subMenu.css('overflow', 'unset').css('overflow', 'scroll');
          }, 50);

          // Add 'active' to all parent sub-menus
          $subMenu.parents('.sh_sub-menu').addClass('active');
        }

        // Scroll to the top of the menu
        $('#main-menu').animate(
          {
            scrollTop: 0,
          },
          0
        );

        // Add a back button to the submenu if it doesn't already exist
        if ($subMenu.children('.sh_back-button').length === 0) {
          const parentText = $subMenu
            .prev('a')
            .clone()
            .children()
            .remove()
            .end()
            .text();
          $subMenu.prepend(
            '<li class="sh_back-button"><a class="tw-px-6	tw-font-bold tw-flex tw-items-center tw-h-15 tw-bg-dh_gray tw-text-black tw-mb-9 hover:text-dh_primary"  href="#"><i class="fa-solid fa-chevron-left"></i>' +
              parentText +
              '</a></li>'
          );
        }
      }
    );

    // Handle the back functionality
    $('#main-menu').on('click', '.sh_back-button > a', function (event) {
      event.preventDefault();

      const $currentMenu = $(this).closest('.sh_sub-menu');
      const $parentMenu = $currentMenu.parent().closest('.sh_sub-menu');

      // Animate the current menu out to the right
      $currentMenu.animate({ left: '100%' }, 200, function () {
        // After the animation, hide the current menu
        $currentMenu
          .removeClass('active')
          .css({ left: '100%', overflow: 'unset' });
      });

      $currentMenu.removeClass('current-slide');

      // Show the parent menu if exists
      if ($parentMenu.length) {
        $parentMenu.css({ left: '0', overflow: 'scroll' }).addClass('active');
        $parentMenu.addClass('current-slide');
      } else {
        $('#menu-hauptmenue-mobile').removeClass('submenu-active');
      }
    });
  }
}
