import $ from '../jquery-shim.js';

export function ajaxAddToCartHandler() {
  $('.woocommerce .products').on('click', '.quantity input', function () {
    return false;
  });
  $('.woocommerce .products').on('change input', '.quantity .qty', function () {
    let add_to_cart_button = $(this)
      .parents('.product')
      .find('.add_to_cart_button');

    // For AJAX add-to-cart actions
    add_to_cart_button.attr('data-quantity', $(this).val());

    // For non-AJAX add-to-cart actions
    add_to_cart_button.attr(
      'href',
      '?add-to-cart=' +
        add_to_cart_button.attr('data-product_id') +
        '&quantity=' +
        $(this).val()
    );
  });
  $('.product-small.product').on('change input', '.quantity .qty', function () {
    let add_to_cart_button = $(this)
      .parent()
      .parent()
      .find('.add_to_cart_button');

    // For AJAX add-to-cart actions
    add_to_cart_button.attr('data-quantity', $(this).val());

    // For non-AJAX add-to-cart actions
    add_to_cart_button.attr(
      'href',
      '?add-to-cart=' +
        add_to_cart_button.attr('data-product_id') +
        '&quantity=' +
        $(this).val()
    );
  });

  // Trigger on Enter press
  $('.woocommerce .products').on('keypress', '.quantity .qty', function (e) {
    if ((e.which || e.keyCode) === 13) {
      $(this).parents('.product').find('.add_to_cart_button').trigger('click');
    }
  });
}
