import $ from '../jquery-shim.js';
import { toggleAccordion } from '../global/accordion.js';
import { initializeElfbarCalculator } from '../global/elfbar-calculator.js';

export function toggleCategoryBlogSidebar() {
  $(document).ready(function () {
    $('.sh_open-blog').on('click', function (event) {
      event.preventDefault();

      const blogId = $(this).closest('[data-blog-id]').data('blog-id');
      const content = $(`[data-blog-content-id="${blogId}"]`).html();

      $('#sh_toggle-sidebar-content').html(content);
      $('#sh_toggle-sidebar').removeClass('tw-hidden');

      // Delay the addition of the animation class for smooth entrance
      setTimeout(() => {
        $('#sh_toggle-sidebar-animate')
          .removeClass('tw-translate-x-full')
          .addClass('tw-translate-x-0');
      }, 10);

      toggleAccordion();
      initializeElfbarCalculator();

      event.stopPropagation();
    });

    $('.sh_toggle-sidebar-close').on('click', function () {
      // Reverse the animation
      $('#sh_toggle-sidebar-animate')
        .removeClass('tw-translate-x-0')
        .addClass('tw-translate-x-full');

      setTimeout(() => {
        $('#sh_toggle-sidebar').addClass('tw-hidden');
      }, 300);
      toggleAccordion();
    });

    $(document).on('click', function (event) {
      const $contentArea = $('#sh_toggle-sidebar-content');
      const $sidebar = $('#sh_toggle-sidebar');

      if (
        !$contentArea.is(event.target) &&
        $contentArea.has(event.target).length === 0 &&
        $sidebar.is(':visible')
      ) {
        $('#sh_toggle-sidebar-animate')
          .removeClass('tw-translate-x-0')
          .addClass('tw-translate-x-full');

        setTimeout(() => {
          $('#sh_toggle-sidebar').addClass('tw-hidden');
        }, 300);
      }
    });

    $('#sh_toggle-sidebar-content').on('click', function (event) {
      event.stopPropagation();
    });
  });
}
